const ptBR = {
  common: {
    decimal: {
      value: '{{value, number(minimumFractionDigits: 2)}} ',
    },
    monetary: {
      symbol: 'R$',
      value: '{{value, number(minimumFractionDigits: 2)}} ',
      symbolValue: '$t(common.monetary.symbol) $t(common.monetary.value)',
    },
  },
  header: {
    search: 'O que você está procurando?',
    deliveryDateVOL: 'Data de entrega (VOL)',
    deliveryDate: 'Data de entrega',
    billingDate: 'Data de faturamento',
    userMenu: 'Usuário',
    myOrders: 'Meus pedidos',
    myOrderIntentions: 'Gerenciamento de Pedidos',
    logout: 'Encerrar sessão',
    support: 'Suporte Veiling',
    apps: 'Meus apps',
    qrcode: 'Comprar por QR Code',
    contacts: 'Contatos',
  },
  footer: {
    univeiling: 'UniVeiling',
    cvhnet: 'CVHNet',
    address: 'Endereço',
    street: 'Rodovia SP 107 - KM 27 - S/Nº ',
    cityStateZipCode: 'Santo Antônio da Posse - SP. CEP: 13.833-585',
    officeHours: 'Horários',
    attendence: '<0>Atendimento</0>: Das 7h00 às 17h00',
    auction:
      '<0>Leilão</0>: :Segunda e quarta-feira às 6h<br/>Terça e sexta-feira às 7h',
    noAuction: '(Não há leilão às quintas-feiras)',
    warningAge:
      'Não é permitida a entrada de menores de 13 anos nas dependências da CVH',
    sac: 'SAC',
    phoneNumber: '0300 775 8955',
    email: 'sac@cvh.com.br',
    privacyWarning: 'Aviso de privacidade',
    veilingHolambraProduct: 'Um produto de Veiling Holambra',
  },
  home: {
    basedOnLastVisit: 'Baseado na sua última visita',
    mostSearchProduts: 'Produtos mais procurados',
    mostBuyByCategory: 'Mais vendidos por categoria',
  },
  generic: {
    inputDatePlaceholder: 'Selecione a Data',
  },
  productList: {
    filter: 'Filtros',
    showingResults: 'Exibindo {{total}} resultado(s)',
    icmsDisclamer: 'Os valores exibidos não incluem o ICMS.',
  },
  shoppingCart: {
    yourCart: 'Seu carrinho',
    showingResults: 'Exibindo {{total}} resultado(s)',
    continueShopping: 'Continuar comprando',
    volProducts: 'Produtos VOL',
    lkpProducts: 'Produtos LKP',
    expiresIn: '(O carrinho será confirmado em {{value}}})',
    productExpiresIn: 'O carrinho será confirmado em {{value}}}',
    itemsQuantity: 'Quantidade de itens',
    deliveryDate: 'Data de Entrega',
    billingDate: 'Data de Faturamento',
    total: 'Total',
    checkout: 'Finalizar compra',
  },
  myOrders: {
    title: 'Meus pedidos',
    legend: 'Aqui você encontra os resumos das suas compras realizadas',
    orderType: {
      formFilter: {
        saleDate: 'A partir de:',
        filterDateBy: 'Filtrar por:',
        saleType: 'Tipo de compra',
        status: 'Status:',
        producer: 'Produtor',
        productDescription: 'Produto:',
        buyDate: 'Data de Compra',
        deliveryDate: 'Data de Entrega',
        fatDate: 'Data de Faturamento',
        all: 'Todos',
        directed: 'No sítio(direcionada)',
        vol: 'No sítio',
        lkp: 'LKP',
        lkpTransit: 'LKP em transito',
        generate: 'Gerado',
        notGenerate: 'Não Gerado',
        open: 'Aberto',
        process: 'Processando',
        programmed: 'Programado',
        canceled: 'Cancelado',
        orderNumber: 'Número do pedido:',
        accounts: 'Usuários',
      },
      justificationModal: {
        title: 'Justificativa',
      },
      table: {
        saleType: 'Tipo de compra',
        key: 'Chave',
        order: 'Pedido/Transação',
        orderPrint: 'N.Pedido',
        buyDate: 'Data da Compra',
        comercialDate: 'Data Comercial',
        barcode: 'Código de barras',
        description: 'Produto/ Código do produto',
        message: 'Mensagem',
        productCode: 'Código do produto',
        embValue: 'EMB',
        valueEmb: 'Valor EMB',
        qtdUnit: 'QE X QpE',
        totalUnit: 'Total Unid',
        vlrUnit: 'Vlr Unit',
        vlrTotal: 'Vlr Total',
        addDisc: ' Adic / Desc',
        vlrUnitTotal: 'Vlr Total Unit',
        shippingFee: 'Valor de frete',
        shippingUnit: 'Vlr Un. Frete',
        shippingTotal: 'Vlr Total Frete',
        orderDate: 'Data de compra',
        deliveryDate: 'Data de Entrega',
        totalValue: 'Valor total',
        orderNumber: 'Pedido',
        billingDate: 'Data de Faturamento',
        auctionDate: 'Data do Leilão',
        transaction: 'Transação',
        producer: 'Produtor',
        producerCode: 'Código do produtor',
        product: 'Produto',
        quality: 'Qld',
        packing: 'Emb.',
        packingQuantity: 'QE',
        unitPerPacking: 'QpE',
        packingPrice: 'Valor da Emb.',
        unitPrice: 'Valor Unitário',
        status: 'Status',
        statusDescription: 'Descrição do status',
        user: 'Usuário',
        observation: 'Observação',
        unitQuantity: 'Total Unidades',
        actions: {
          showDetails: 'Ver Detalhes',
        },
      },
    },
  },
  myOrderIntentions: {
    title: 'Gerenciamento de Pedidos',
    legend: 'Aqui você encontra os resumos das suas intenções de compra',
    subtitleNoRepublish:
      'Não foi possível republicar a intenção de compra devido a data de entrega estar expirada. Altere a data de entrega e publique novamente a intenção de compra!',
    detailsModal: {
      detailsHeader: 'Detalhes',
      order: 'Pedido:',
      accord: 'Acordo:',
      buyOrder: 'Ordem de Compra:',
      origin: 'Origem:',
      publishedBy: 'Publicado por:',
      answeredBy: 'Respondido por:',
      modifiedAt: 'Data da Alteração:',
      modifiedBy: 'Alterado por:',
      canceledBy: 'Alterado/Cancelado por:',
      deliveryDate: 'Data de Entrega:',
      commercialDate: 'Data Comercial:',
      customer: 'Produtor:',
      publishDate: 'Data da Publicação:',
      canceledDate: 'Data de Alteração/Cancelamento:',
      cancelReason: 'Motivo de Alteração/Cancelamento:',
      changeReason: 'Motivo da Alteração:',
      justifyHeader: 'Justificativa',
      observationOrder: 'Observação do Pedido',
    },
    justificationModal: {
      title: 'Justificativa',
    },
    observationModal: {
      title: 'Observação',
    },
    formFilter: {
      since: 'de',
      until: 'até',
      saleDate: 'Data:',
      select: 'Selecione',
      filterDateBy: 'Filtrar por:',
      status: 'Status:',
      customers: 'Chave:',
      statusOptions: {
        generate: 'Confirmado',
        notGenerate: 'Confirmado Parcial',
        changed: 'Confirmado com Alteração',
        open: 'Pendente',
        process: 'Aguardando geração do Pedido',
        waitingProducer: 'Aguardando Produtor',
        refused: 'Recusado',
        canceled: 'Cancelado',
        programmed: 'Expirado',
        error: 'Erro',
        waitingAndOpen: 'Pendente e Aguardando Produtor',
      },
      originOptions: {
        orderIntention: 'Intenção de compra (IC)',
        directSale: 'Venda direta (VD)',
      },
      producer: 'Produtor',
      productDescription: 'Produto:',
      publishDate: 'Data de Publicação',
      deliveryDate: 'Data de Entrega',
      fatDate: 'Data de Faturamento',
      all: 'Todos',
      lkpTransit: 'LKP em transito',
      orderNumber: 'Número do pedido:',
      accounts: 'Usuários',
    },
    table: {
      origin: 'Origem',
      order: 'Pedido',
      orderPrint: 'N.Pedido',
      buyDate: 'Data da Compra',
      comercialDate: 'Data Comercial',
      barcode: 'Código de barras',
      description: 'Produto/ Código do produto',
      message: 'Mensagem',
      productCode: 'Código do produto',
      embValue: 'EMB',
      valueEmb: 'Valor EMB',
      qtdUnit: 'QE X QpE',
      totalUnit: 'Total Unid',
      shippingFee: 'Valor un. Frete',
      shippingFeeTotal: 'Valor Total. Frete',
      vlrUnit: 'Vlr Unit.',
      vlrTotal: 'Vlr Total',
      addDisc: ' Adic / Desc',
      vlrUnitTotal: 'Vlr Total Unit',
      orderDate: {
        line01: 'Data',
        line02: 'Comercial',
      },
      deliveryDate: {
        line01: 'Data',
        line02: 'Entrega',
        time: 'horário',
      },
      totalValue: 'Valor total',
      orderNumber: 'Pedido',
      billingDate: 'Data de Faturamento',
      auctionDate: 'Data do Leilão',
      transaction: 'Transação',
      producer: 'Produtor',
      productDescription: 'Descrição do Produto',
      quality: 'Qld.',
      packing: 'Emb.',
      packingQuantity: 'QE',
      unitPerPacking: 'QpE',
      packingPrice: 'Valor da Emb.',
      unitPrice: 'Valor Unitário',
      rowDropdown: {
        seeDetails: 'Ver detalhes',
      },
      actions: {
        showDetails: 'Ver detalhes',
      },
      status: 'Status',
      statusDescription: 'Descrição do status',
      publishedBy: 'Publicado por',
      unitQuantity: 'Total Unidades',
    },
    exportReport: 'Exportar Relatório',
    searchAgain: 'Por favor, efetue uma nova busca e tente novamente!',
  },
  breadcrumb: {
    store: 'Loja',
    products: 'Produtos',
    shoppingCart: 'Carrinho',
    directedOffers: 'Ofertas Direcionadas',
    myOffers: 'Minhas Ofertas',
    myOrders: 'Meus pedidos',
    myOrderIntentions: 'Minhas intenções de compra',
  },
  generals: {
    category: 'Categoria:',
    group: 'Grupo:',
    origin: 'Origem:',
    opNature: 'Natureza Operação:',
    product: 'Produto:',
    producer: 'Produtor:',
    publishBy: 'Publicado por(vendedor):',
    quality: 'Qualidade:',
    selectPlaceholder: 'Selecione',
    size: 'Tamanho:',
  },
  buyIntention: {
    titleModal: 'Cadastrar Intenção de Compra',
    stepOne: 'Montar Pedido',
    stepTwo: 'Resumo',
    cancel: 'Voltar',
    continue: 'Continuar',
    registerIntention: 'Cadastrar Intenção de Compra',
    observationModalTitle: 'Adicionar observações',
    observationModalPlaceholder: 'Digite suas observações',
    form: {
      customer: 'Cliente',
      deliveryDate: 'Data de Entrega',
      comercialDate: 'Data Comercial',
      productsLine: 'Produtos',
      product: 'Produto',
      producer: 'Produtor',
    },
    table: {
      productDescription: 'Descrição do produto',
      producer: 'Produtor',
      quality: 'Qld.',
      packing: 'Emb.',
      qe: 'QE',
      qpe: 'QpE',
      unitValue: 'Valor un. R$',
      noICMS: '*sem ICMS',
      totalValue: 'Valor Total',
      observation: 'Observação',
      bought: '*Produto comprado nessa intenção',
      intention: '*Produto adicionado',
      shippingFee: 'Valor un. Frete',
      shippingTotalFee: 'Valor Total. Frete',
    },
    offers: {
      offersFound: 'Ofertas encontradas',
      checkout: 'Finalizar compra',
      back: 'Voltar',
      totalPrice: 'Valor total',
    },
    modals: {
      registerBuyIntention: 'Cadastrar Intenção de Compra',
      showOffers: 'Ver ofertas',
      questionOffers:
        'Encontramos ofertas ativas deste produto no e-commerce. Deseja visualizar as ofertas antes de cadastrar uma Intenção de Compra?',
      offersTitle: 'Atenção',
    },
  },
};

export default ptBR;
