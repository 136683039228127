import React, { useCallback, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  CloseOutlined,
  DownloadOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { Button, Divider, Form } from 'antd';
import * as XLSX from 'xlsx';
import moment from 'moment';

import { endpoints, useGetPostCheckoutMutation } from 'services/ecommerceApi';
import { cartSelectors, sessionSelectors } from 'store/state/selectors';
import Monetary from 'components/UI/Data/Monetary';
import CartItem from 'model/CartItem';
import { showAlertError } from 'services/alertService';
import { roundDecimals } from 'utils/util';
import * as S from './styles';
import TableSummary from './Table';

type PropsOrderSummary = {
  modalIsVisible: boolean;
  setModalIsVisible: () => void;
  showModalSuccess: () => void;
  isLkp?: boolean;
};

export default function ModalOrderSummary({
  modalIsVisible,
  setModalIsVisible,
  showModalSuccess,
  isLkp = false,
}: PropsOrderSummary) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { customers } = useSelector(sessionSelectors.getRoot);
  const selectedCustomerId = useSelector(
    sessionSelectors.getSelectedCustomerId,
  );
  const lkpOfferId = useSelector(cartSelectors.getLkpOfferId);
  const volOfferId = useSelector(cartSelectors.getVolOfferId);
  const billingDate = useSelector(sessionSelectors.getSelectedBillingDate);
  const user = useSelector(cartSelectors.getUser);
  const lkpCreationDate = useSelector(cartSelectors.getLKpCreationDate);
  const volCreationDate = useSelector(cartSelectors.getVolCreationDate);
  const deliveryDate = useSelector(sessionSelectors.getSelectedDeliveryDate);
  const totalPriceLkp = useSelector(cartSelectors.getLkpTotal);
  const totalPriceVol = useSelector(cartSelectors.getVolTotal);

  const customerSelected = useMemo(
    () => customers.find((customer) => customer.id === selectedCustomerId),
    [],
  );

  const cartId = isLkp ? lkpOfferId : volOfferId;
  const cartItems = endpoints.getCartItems.useQueryState({ cartId })?.data;

  const totalValueMarkup = useMemo(() => {
    let totalMarkup = 0.0;

    cartItems?.forEach((cartItem) => {
      totalMarkup += cartItem.shippingFee
        ? cartItem.shippingFee * cartItem.unitQuantity
        : 0;
    });

    return totalMarkup;
  }, [cartItems]);
  const totalVolAndShipping = useMemo(
    () => totalPriceVol + totalValueMarkup,
    [totalValueMarkup, totalPriceVol],
  );

  const totalLkpAndShipping = useMemo(
    () => totalPriceLkp + totalValueMarkup,
    [totalValueMarkup, totalPriceLkp],
  );
  const [postCheckout, { isSuccess, isLoading, error }] =
    useGetPostCheckoutMutation();

  useEffect(() => {
    if (isSuccess) {
      setModalIsVisible();
      showModalSuccess();
    }
  }, [isSuccess, setModalIsVisible, showModalSuccess]);

  useEffect(() => {
    if (error) {
      const errorData: any = { ...error };
      showAlertError(dispatch, JSON.stringify(errorData.data));
    }
  }, [error, dispatch]);

  const generateXLSXBuffer = () => {
    if (cartItems && cartItems.length) {
      const itemsToFile = cartItems.map((item: CartItem) => ({
        [isLkp ? 'Data do Leilão' : 'Data de Faturamento']: moment(
          item.auctionDate,
        ).format('L'),
        Produto: item.description,
        Qualidade: item.quality,
        Produtor: item.producerName,
        Embalagem: item.packingName,
        QE: item.packingQuantity,
        QpE: item.unitPerPacking,
        'Total Unidades': item.unitQuantity,
        'Valor da Emb.': item.packingPrice,
        'Valor Frete': item.shippingFee,
        'Valor Unitário': item.unitPrice,
        'Valor total': item.subTotal,
        Usuário: user,
        Observação: item.observation,
      }));
      const ws = XLSX.utils.json_to_sheet(itemsToFile);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      return excelBuffer;
    }
    return null;
  };

  const tableSummaryRender = (cartItems: CartItem[]) => (
    <TableSummary
      cartItems={
        isLkp
          ? cartItems.filter((value) => value.lkpOfferId)
          : cartItems.filter((value) => value.volOfferId)
      }
      billingDate={billingDate}
      user={user}
      isLkp={isLkp}
    />
  );

  const handleClickPrint = () => {
    if (cartItems && cartItems.length) {
      const tempDiv = document.createElement('div');
      ReactDOM.render(tableSummaryRender(cartItems), tempDiv);
      const frame = document.createElement('iframe');
      frame.style.display = 'none';
      document.body.appendChild(frame);
      frame.contentDocument?.write(tempDiv?.innerHTML || '');
      frame.focus();
      frame.contentWindow?.print();
      frame.parentNode?.removeChild(frame);
    }
  };

  const downloadFile = (blob: Blob, filename: string) => {
    const objectURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = objectURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
    tempLink.parentNode?.removeChild(tempLink);
  };

  const handleClickDownload = async () => {
    const buffer = generateXLSXBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    downloadFile(blob, `Resumo pedido ${moment().format('L')}.xlsx`);
  };

  const submitForm = useCallback(
    (valuesForm: any) => {
      postCheckout({
        cartId,
        xped: valuesForm.xped,
        isVolOffer: !isLkp,
        isLkpOffer: isLkp,
      });
    },
    [isLkp, postCheckout, cartId],
  );

  const renderOrderDate = useMemo(() => {
    if (!isLkp) {
      return (
        <S.GroupInformationDate className="margin-left">
          <S.LabelInformationDate>
            <S.LabelDate>Data de entrega na CVH:</S.LabelDate>
            <S.ValueInformationDate>
              {moment(deliveryDate).format('L')}
            </S.ValueInformationDate>
          </S.LabelInformationDate>
        </S.GroupInformationDate>
      );
    }
    return <S.GroupInformationDate className="margin-left" />;
  }, [isLkp, deliveryDate]);
  return (
    <S.ModalOrderSummary
      visible={modalIsVisible}
      width="150em"
      onCancel={setModalIsVisible}
      footer={
        <S.ModalFooterOrderSummary>
          <S.ComponentInformationvalue>
            <S.TextValue>
              Valor total
              <S.Value>
                R${' '}
                <Monetary
                  value={
                    isLkp
                      ? roundDecimals(totalLkpAndShipping, 2)
                      : roundDecimals(totalVolAndShipping, 2)
                  }
                />
              </S.Value>
            </S.TextValue>
          </S.ComponentInformationvalue>

          <S.FormXped
            form={form}
            onFinish={(valuesForm: any) => {
              submitForm(valuesForm);
            }}
          >
            <S.ComponentInputXped>
              <S.TextExped>Informe o XPED:</S.TextExped>
              <Form.Item
                name="xped"
                rules={[
                  {
                    required: customerSelected?.hasXmlOrder,
                    message: 'O campo é obrigatório.',
                  },
                ]}
              >
                <S.InputXped />
              </Form.Item>
            </S.ComponentInputXped>
          </S.FormXped>

          <Button type="primary" onClick={() => form.submit()}>
            Finalizar Compra
          </Button>
        </S.ModalFooterOrderSummary>
      }
    >
      <S.ModalHeaderOrderSummary>
        <span>{isLkp ? 'Resumo do Pedido LKP' : 'Resumo do Pedido VOL'}</span>
        <CloseOutlined onClick={setModalIsVisible} />
      </S.ModalHeaderOrderSummary>
      <S.ModalBodyOrderSummary>
        <S.ModalSubheaderOrderSumary>
          <S.GroupDateInformation>
            <S.GroupInformationDate>
              <S.LabelInformationDate>
                <S.LabelDate>Data do pedido:</S.LabelDate>
                <S.ValueInformationDate>
                  {moment(isLkp ? lkpCreationDate : volCreationDate).format(
                    'L',
                  )}
                </S.ValueInformationDate>
              </S.LabelInformationDate>
            </S.GroupInformationDate>
            {renderOrderDate}
          </S.GroupDateInformation>
          <S.GroupAction>
            <Button
              className="margin-right"
              icon={<DownloadOutlined />}
              onClick={() => handleClickDownload()}
            />
            <Button
              icon={<PrinterOutlined />}
              onClick={() => handleClickPrint()}
            />
          </S.GroupAction>
        </S.ModalSubheaderOrderSumary>

        <Divider />

        {cartItems && cartItems.length && tableSummaryRender(cartItems)}
      </S.ModalBodyOrderSummary>
      {isLoading && (
        <S.Overlay>
          <S.Loader spinning={isLoading} size="large" />
        </S.Overlay>
      )}
    </S.ModalOrderSummary>
  );
}
