import { OfferType } from 'model/types';
import React from 'react';

type OfferTypeTextProps = {
  offerType: OfferType;
};

export const offerTypeText: Record<OfferType, string> = {
  onSite: 'no sítio',
  received: 'recepcionado (LKP)',
  inTransit: 'no sítio (LKP)',
  directed: 'oferta direcionada'
};

export default function OfferTypeText({ offerType }: OfferTypeTextProps) {
  return <span>{offerTypeText[offerType]}</span>;
}
