/* eslint-disable no-plusplus */
import { Button, FormInstance, Row, Table, Tag, Tooltip } from 'antd';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Monetary from 'components/UI/Data/Monetary';
import { OfferTypeEnum } from 'enums/offerType';
import { Order, StatusType } from 'model/Order';
import { dateUtils } from 'utils/date';
import ReactDOM from 'react-dom';
import { useCallback, useContext, useMemo } from 'react';
import { generateOrderParam, roundDecimals } from 'utils/util';
import { generateOrdersExcel } from 'services/ordersService';
import { sessionSelectors } from 'store/state/selectors';
import FormFiltersFields from 'model/FormFiltersFields';
import { useSelector } from 'react-redux';
import { myOrdersContext } from 'contexts/MyOrdersContext';
import ShippingFee from 'components/UI/Data/ShippingFee';
import { siteName } from 'screens/MyOrderIntentions/DetailsModal/styles';
import { ActionsContainer } from '../styles';
import { statusMap } from './maps/status';
import NestedTable from './NestedTable';

interface Props {
  offerType: OfferTypeEnum;
  isLoading?: boolean;
  data: Order[] | undefined;
  form: FormInstance;
}

export default function MyOrdersTable({
  data,
  offerType,
  isLoading = false,
  form,
}: Props) {
  const { t: translate } = useTranslation();
  const customerId = useSelector(sessionSelectors.getSelectedCustomerId);
  const { setLoading } = useContext(myOrdersContext);

  const downloadFile = useCallback((blob: Blob, fileName: string) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.style.display = 'none';
    link.download = fileName;
    link.click();
  }, []);

  const convertToBlob = useCallback((binaryStringData: string) => {
    const binaryString = window.atob(binaryStringData);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    const fileData = bytes;

    const blob = new Blob([fileData], { type: 'application/octet-stream' });
    return blob;
  }, []);

  const handleClickDownload = () => {
    const formFilters: FormFiltersFields = form.getFieldsValue();
    const filtersParams = generateOrderParam(formFilters);
    setLoading(true);
    generateOrdersExcel({
      ...filtersParams,
      'Data.CustomerId': customerId,
    })
      .then((response) => {
        setLoading(false);
        const blob = convertToBlob(response.data.data);
        downloadFile(blob, 'pedidos.xlsx');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const expandedRowRender = (row: Order[]) => <NestedTable row={row} />;

  const sortPrintTableOrders = useCallback(
    () =>
      data?.sort((actual: Order, next: Order) =>
        actual.description.localeCompare(next.description),
      ),
    [data],
  );

  const handleClickPrint = () => {
    const tempDiv = document.createElement('div');
    const sortedOrders = sortPrintTableOrders();
    ReactDOM.render(expandedRowRender(sortedOrders ?? []), tempDiv);
    tempDiv.innerHTML += `<style type="text/css">table {border-collapse: collapse;}tr { border-bottom: 1px solid #bab3b3;}</style>`;
    const frame = document.createElement('iframe');
    frame.style.display = 'none';
    document.body.appendChild(frame);
    frame.contentDocument?.write(tempDiv?.innerHTML || '');
    frame.focus();
    frame.contentWindow?.print();
    frame.parentNode?.removeChild(frame);
  };

  const producerColumn = useMemo(
    () => (
      <>
        {translate('myOrders.orderType.table.producer')} <br />{' '}
        {translate('myOrders.orderType.table.producerCode')}
      </>
    ),
    [],
  );
  const productColumn = useMemo(
    () => (
      <>
        {translate('myOrders.orderType.table.product')} <br />{' '}
        {translate('myOrders.orderType.table.productCode')}
      </>
    ),
    [],
  );

  const packValueColumn = useMemo(
    () => (
      <>
        {translate('myOrders.orderType.table.embValue')} <br />{' '}
        {translate('myOrders.orderType.table.valueEmb')}
      </>
    ),
    [],
  );

  const qeQpeTotalColumn = useMemo(
    () => (
      <>
        {translate('myOrders.orderType.table.qtdUnit')} <br />{' '}
        {translate('myOrders.orderType.table.totalUnit')}
      </>
    ),
    [],
  );
  const vlrUnitTotalColumn = useMemo(
    () => (
      <>
        {translate('myOrders.orderType.table.vlrUnit')} <br />{' '}
        {translate('myOrders.orderType.table.vlrTotal')}
      </>
    ),
    [],
  );
  const vlrShippingFeeColumn = useMemo(
    () => (
      <>
        {translate('myOrders.orderType.table.shippingUnit')} <br />{' '}
        {translate('myOrders.orderType.table.shippingTotal')}
      </>
    ),
    [translate],
  );
  const vlrTotalUnitColumn = useMemo(
    () => (
      <>
        {translate('myOrders.orderType.table.vlrUnitTotal')} <br />{' '}
        {translate('myOrders.orderType.table.vlrTotal')}
      </>
    ),
    [],
  );

  return (
    <>
      <Row justify="end">
        <ActionsContainer>
          <Button
            className="margin-right"
            onClick={() => handleClickDownload()}
            icon={<DownloadOutlined />}
          />
          <Button
            className="margin-right"
            onClick={() => handleClickPrint()}
            icon={<PrinterOutlined />}
          />
        </ActionsContainer>
      </Row>
      <Table loading={isLoading} dataSource={data} pagination={false}>
        <Table.Column
          title={translate('myOrders.orderType.table.status')}
          dataIndex="status"
          key="status"
          align="left"
          render={(status: StatusType, order: Order) => {
            if (statusMap[status]) {
              const { color, description } = statusMap[status];
              return (
                <Tooltip title={order.resultMessage}>
                  <Tag color={color}>{description}</Tag>
                </Tooltip>
              );
            }
            return status;
          }}
        />

        <Table.Column
          title={translate('myOrders.orderType.table.key')}
          dataIndex="accountCode"
          key="accountCode"
          align="left"
          sorter={(a: Order, b: Order) => a.accountCode - b.accountCode}
        />
        <Table.Column
          title={translate('myOrders.orderType.table.order')}
          dataIndex="orderNumber"
          key="orderNumber"
          align="left"
          sorter={(a: Order, b: Order) => a.orderNumber - b.orderNumber}
        />
        <Table.Column
          title={translate('myOrders.orderType.table.saleType')}
          dataIndex="saleType"
          key="saleType"
          align="left"
        />

        <Table.Column
          title={translate('myOrders.orderType.table.orderDate')}
          dataIndex="saleConclusion"
          key="saleConclusion"
          align="left"
          defaultSortOrder="descend"
          sorter={(actual: Order, next: Order) =>
            dateUtils.orderDate(actual.saleConclusion, next.saleConclusion)
          }
          render={(saleConclusion: string) =>
            dateUtils.formatDate(saleConclusion, 'DD/MM/YYYY HH:mm')
          }
        />
        <Table.Column
          title={translate('myOrders.orderType.table.deliveryDate')}
          dataIndex="deliveryDate"
          key="deliveryDate"
          align="left"
          sorter={(actual: Order, next: Order) => {
            if (actual.isLKPSale) {
              return dateUtils.getDateStringNumber(actual.deliveryDate);
            }
            if (next.isLKPSale) {
              return dateUtils.getDateStringNumber(next.deliveryDate);
            }
            return dateUtils.orderDate(actual.deliveryDate, next.deliveryDate);
          }}
          render={(deliveryDate: string, order: Order) => {
            if (!order.isLKPSale) {
              return dateUtils.formatDate(deliveryDate, 'DD/MM/YYYY');
            }
            return '-';
          }}
        />

        <Table.Column
          title={translate('myOrders.orderType.table.billingDate')}
          dataIndex="billingDate"
          key="billingDate"
          sorter={(actual: Order, next: Order) =>
            dateUtils.orderDate(actual.billingDate, next.billingDate)
          }
          align="left"
          render={(billingDate: string) =>
            dateUtils.formatDate(billingDate, 'DD/MM/YYYY')
          }
        />
        <Table.Column
          title={producerColumn}
          dataIndex="siteName"
          key="siteName"
          align="left"
          render={(siteName: string, order: Order) => (
            <>
              {siteName} <br /> {order.siteCode}
            </>
          )}
        />

        <Table.Column
          title={translate('myOrders.orderType.table.barcode')}
          dataIndex="productBarCode"
          key="productBarCode"
          align="left"
          sorter={(a: Order, b: Order) => a.productBarCode - b.productBarCode}
        />
        <Table.Column
          title={productColumn}
          dataIndex="description"
          key="description"
          align="left"
          sorter={(a: Order, b: Order) =>
            a.description.localeCompare(b.description)
          }
          render={(description: string, order: Order) => (
            <>
              {description} <br /> {order.productCode}
            </>
          )}
        />

        <Table.Column
          title={translate('myOrders.orderType.table.quality')}
          dataIndex="quality"
          key="quality"
          align="left"
        />
        <Table.Column
          title={packValueColumn}
          dataIndex="packingName"
          key="packingName"
          align="left"
          render={(packingName: string, order: Order) => (
            <>
              {packingName}
              <br /> <Monetary symbol value={order.packingPrice} />{' '}
            </>
          )}
        />
        <Table.Column
          title={qeQpeTotalColumn}
          dataIndex="packingQuantity"
          key="packingQuantity"
          align="left"
          render={(packingQuantity: number, order: Order) => (
            <>
              {packingQuantity} x {order.unitPerPacking} <br />{' '}
              {order.unitQuantity}
            </>
          )}
        />

        <Table.Column
          title={vlrUnitTotalColumn}
          dataIndex="unitPrice"
          key="unitPrice"
          align="left"
          render={(unitPrice: number, order: Order) => (
            <> 
              <Monetary
                symbol
                value={roundDecimals(unitPrice, 6)}
              />{' '}
              <br />  
              <Monetary symbol value={order.subTotal} />
            </>
          )}
        />
        <Table.Column
          title={vlrShippingFeeColumn}
          dataIndex="shippingFee"
          key="shippingFee"
          align="left"
          render={(shippingFee: number, order: Order) => (
            <>
              <ShippingFee value={roundDecimals(shippingFee ?? 0.0, 6)} />
              <br />
              <ShippingFee
                value={roundDecimals(shippingFee * order.unitQuantity, 6)}
              />
            </>
          )}
        />

        <Table.Column
          title={vlrTotalUnitColumn}
          dataIndex="unitPriceConsolidated"
          key="unitPriceConsolidated"
          align="left"
          render={(unitPriceConsolidated: number, order: Order) => (
            <>
              <Monetary
                symbol
                value={roundDecimals(
                  unitPriceConsolidated + order.shippingFee,
                  6,
                )}
              />
              <br />
              <Monetary
                symbol
                value={roundDecimals(
                  order.total + order.shippingFee * order.unitQuantity,
                  2,
                )}
              />
            </>
          )}
        />
        <Table.Column
          title={translate('myOrders.orderType.table.addDisc')}
          dataIndex="voucherAmountLabel"
          key="voucherAmountLabel"
          align="left"
        />
        <Table.Column
          title={translate('myOrders.orderType.table.user')}
          dataIndex="userCartInclusion"
          key="userCartInclusion"
          align="left"
          render={(userCartInclusion, order: Order) => (
            <Tooltip title={`${order.accountCode} - ${order.accountName}`}>
              {userCartInclusion}
            </Tooltip>
          )}
          // sorter={(a: Order, b: Order) =>
          //   a.userCartInclusion.localeCompare(b.userCartInclusion)
          // }
        />
        <Table.Column
          title={translate('myOrders.orderType.table.observation')}
          dataIndex="observation"
          key="observation"
          align="left"
        />
      </Table>
    </>
  );
}
