export const offerTypeColor: Record<
  number,
  { backgroundColor: string; text: string }
> = {
  0: {
    backgroundColor: '#FAAD14',
    text: 'no sítio',
  },
  1: {
    backgroundColor: '#52C41A',
    text: 'recepcionado (LKP)',
  },
  2: {
    backgroundColor: '#13C2C2',
    text: 'no sítio (LKP)',
  },
};
