import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export type ContainerType = 'small' | 'medium';
interface ContainerProps {
  backgroundColor: string;
  type?: ContainerType;
}

const containerMap: Record<ContainerType, FlattenSimpleInterpolation> = {
  small: css`
    width: auto;
    font-size: 8px;
    height: 14px;
    padding: 2px;

    white-space: nowrap;
  `,
  medium: css`
    width: 168px;
    font-size: 12px;
    height: 20px;
    padding: 0px 8px;
  `,
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};

  ${(props) => containerMap[props.type || 'medium']};
`;

export const Text = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: inherit;
  line-height: 100%;
  color: #ffffff;
`;
