import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment';

import Theme from 'styles/theme';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

import 'moment/locale/pt-br';
import ptBR from 'antd/lib/locale/pt_BR';
import Signalr from 'components/BusinessLogic/Signalr';
import i18n from './i18n';

import Routes from './routes';

import GlobalStyle from './styles/GlobalStyle';

import { store } from './store/store';

function App(): React.ReactElement {
  moment().isoWeek();
  moment.updateLocale('pt-br', {
    week: {
      dow: 0, // Sunday is the first day of the week.
      doy: 4, // The week that contains Jan 4th is the first week of the year.
    },
  });
  moment.locale('pt-br');

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <ConfigProvider locale={ptBR}>
              <Signalr />
              <Routes />
              <GlobalStyle />
            </ConfigProvider>
          </I18nextProvider>
        </BrowserRouter>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
